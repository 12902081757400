
import { defineComponent, reactive, ref, onMounted } from "vue";
import {
  ssHotTop,
  handlerSheetsData,
} from "../../../System/Kufri/NstMapping/utils";
import { message } from "ant-design-vue";
import { NstMappingProps } from "../../../System/Kufri/NstMapping/types";
import { getNstGroupList } from "@/API/closing";
import { saveNstMapping, getImpactData } from "@/API/sysParam";
import "@grapecity/spread-sheets/styles/gc.spread.sheets.excel2016colorful.css";
import GC from "@grapecity/spread-sheets";
import { usetableHeight } from "../../../System/Kufri/KufriPanelControl/utils";

export default defineComponent({
  emits: ["update:visible"],
  props: {
    visible: {
      type: Boolean,
      default: false,
      required: true,
    },
    title: {
      type: String,
      default: "NST Group Maintain",
    },
    makeId: {
      type: Number,
      required: true,
    },
    make: {
      default: "",
      type: String,
    },
  },
  setup(props, { emit }) {
    const { tableHeight } = usetableHeight("#ssHost", "#ssHostvp");
    const tableData = reactive<NstMappingProps[]>([]);
    const ssHotTopInit = () => {
      const spread = new GC.Spread.Sheets.Workbook(
        document.getElementById("ssHost") as HTMLElement
      );
      ssHotTop(spread, tableData);
    };

    const getImpactCode = (params: any) => {
      const param = {
        nstGroupList: params,
        make: props.make,
      };
      getImpactData(param)
        .then((res: any) => {
          if (res.length == 0) {
            message.warning("Sorry,The Table No Data~");
            emit("update:visible", false); //无数据关闭弹窗
          } else {
            tableData.length = 0;
            const tempArr: any = reactive([]);
            res.forEach((ele: any) => {
              const temp: any = {
                id: ele.id,
                Brand: ele.brand ? ele.brand : " ",
                "Type Class": ele.typeClass ? ele.typeClass : " ",
                "NST Group": ele.nstGroup ? ele.nstGroup : " ",
                "NST Group Name": ele.nstGroupName ? ele.nstGroupName : " ",
                "NST Group Name(IPlan)": ele.vehicleNstGroupName
                  ? ele.vehicleNstGroupName
                  : " ",
                "*IMPACT Code": ele.impactCode ? ele.impactCode : " ",
                "*IMPACT Model Name": ele.impactModelName
                  ? ele.impactModelName
                  : " ",
                "*IMPACT Name": ele.impactName ? ele.impactName : " ",
                Validation: ele.validation ? true : false,
                Remark: ele.remark ? ele.remark : " ",
                Tag: ele.tag ? ele.tag : false,
              };
              tempArr.push(temp);
            });
            Object.assign(tableData, handlerSheetsData(tempArr));
            ssHotTopInit();
          }
        })
        .catch((err) => {
          console.log(err, "报错");
        });
    };
    const initNstMapping = () => {
      getNstGroupList({ mcId: props.makeId }).then((res: any) => {
        getImpactCode(res);
      });
    };
    // 保存
    const handlerSave = () => {
      const data: NstMappingProps[] = [];
      Object.assign(data, handlerSheetsData(tableData, false));
      const params = {
        version: null,
        make: props.make,
        year: null,
        data,
      };

      saveNstMapping(params)
        .then(() => {
          message.success("Save successful！");
          emit("update:visible", false); //保存成功关闭弹窗
        })
        .catch((e: any) => {
          console.log(e);
        });
    };

    // 返回
    const handlerBack = () => {
      emit("update:visible", false); //保存成功关闭弹窗
    };
    onMounted(() => {
      // ssHotTopInit()
      initNstMapping();
    });
    return {
      tableHeight,
      ssHotTopInit,
      ssHotTop,
      handlerSave,
      handlerBack,
    };
  },
});
