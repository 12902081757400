
import { defineComponent, ref, reactive, toRefs, h } from "vue";
import BasicUpload from "@/views/Closing/Tab/Components/BasicUpload.vue";
import {
  getCurrentYear,
  getMakeInfos,
  getNewMake,
  getLock,
} from "@/API/closing";
import moment from "moment";

type TargetTpye = {
  target: {
    innerText: string;
  };
};
type DataType = {
  name: string;
  header: any[];
  data: any[];
};
const monthSlots = [
  "01",
  "02",
  "03",
  "04",
  "05",
  "06",
  "07",
  "08",
  "09",
  "10",
  "11",
  "12",
];
export default defineComponent({
  components: {
    BasicUpload,
  },
  emits: ["chang-tab",'change-final'],
  setup(_, { emit }) {
    const yearValue = ref("0000");
    let disableTime = "";
    const dataArray = reactive<DataType[]>([
      { name: "CBU", header: [], data: [] },
      { name: "PBP", header: [], data: [] },
      { name: "VAN", header: [], data: [] },
    ]);
    const uploadDrawer = reactive({
      showDrawer: false,
      make: "",
      makeId: 0,
    });
    const handleTitle = (e: EventTarget | TargetTpye, arg: string) => {
      const [year, month] = (e as TargetTpye).target.innerText.split("-");
      let mcId: number | null;
      switch (arg) {
        case "CBU":
          mcId = dataArray[0].data[3][`${month}mcId`];
          break;
        case "PBP":
          mcId = dataArray[1].data[3][`${month}mcId`];
          break;
        case "VAN":
          mcId = dataArray[2].data[2][`${month}mcId`];
          break;
        default:
          mcId = null;
          break;
      }
      getNewMake({
        mcId,
        year,
        month,
        make: arg,
      }).then((res) => {
        emit('change-final',true)
        emit(
          "chang-tab",
          res.isFinal ? "FinalView" : "DataPreparation",
          arg,
          res.version,
          res.id,
          res.month,
          res.year
        );
      });
    };
    const handleUpload = (record: any, month: string) => {
      // 根据样式类名判断是否能上传
      //if (!~record[month].indexOf("un-upload")) {
        /**
         * 上传逻辑
         */
        uploadDrawer.showDrawer = true;
        uploadDrawer.makeId = record[month + "mcId"];
        uploadDrawer.make = record.make;
      //}
    };
    const tempBack =ref(false) ;
  
    /**
     * 设置单元格wip和final的icon
     */
    const setDataIcon = (flag: unknown) => {
      return !flag
        ? "font_family icon-fuxuankuang"
        : "font_family icon-fuxuankuang-yixuan-xianxing icontrue";
    };
    /**
     * 设置单元格上传icon
     */
    const setDataUpload = (flag: unknown) => {
      return "font_family icon-shangchuan upload";
    };
    /**
     * 设置表头
     * year: 表头年
     * month: 表头月
     * make: CBU/PBP/VAN
     */
    const setHeader = (year: string, month: string, make: string) => {
      const flag = parseInt(disableTime) > parseInt(`${year}${month}`);
      return flag
        ? h(
            "a",
            {
              onClick: ($event: EventTarget) => handleTitle($event, make),
            },
            `${year}-${month}`
          )
        : h(
            "span",
            {
              style: "color: gray",
            },
            `${year}-${month}`
          );
    };
    // 获取make数据
    const getMakeData = async () => {
      const resMake = await getMakeInfos({ year: yearValue.value });
      resMake.CBU.some((item: any)=>{
        if (item.globalLock){
          tempBack.value = true;
          return true;
        }
      })
      dataArray.forEach((item, index) => {
        item.header = [
          {
            title: item.name,
            dataIndex: "name",
            width: "120px",
            align: "center",
          },
        ];
        if (index < 2) {
          item.data = [
            {
              key: "1",
              name: "WIP Version",
            },
            {
              key: "2",
              name: "Final Version",
            },
            {
              key: "3",
              name: "Actual Upload",
            },
            {
              key: "4",
              name: "Locked",
            },
          ];
        } else {
          item.data = [
            {
              key: "1",
              name: "WIP Version",
            },
            {
              key: "2",
              name: "Final Version",
            },
            {
              key: "3",
              name: "Locked",
            },
          ];
        }
        resMake[item.name].forEach((ele: any) => {
          const title = setHeader(ele.year, ele.month, item.name);
          item.header.push({
            title,
            align: "center",
            dataIndex: `${ele.month}`,
            slots: { customRender: `${ele.month}` },
          });
          if (index < 2) {
            item.data.forEach((el) => {
              switch (el.key) {
                case "1":
                  el[ele.month] = setDataIcon(ele.isWip);
                  el.wip = ele.month;
                  break;
                case "2":
                  el[ele.month] = setDataIcon(ele.isFinal);
                  el.final = ele.month;
                  break;
                case "3":
                  el[ele.month] = setDataUpload(ele.isFinal&&!ele.globalLock);
                  el[ele.month + "mcId"] = ele.mcId;
                  el.make = item.name.toLowerCase();
                  break;
                case "4":
                  el[ele.month] = ele.globalLock;
                  el[ele.month + "mcId"] = ele.mcId;
                  break;
                default:
                  console.warn("error");
                  break;
              }
            });
          } else {
            item.data.forEach((el: any) => {
              switch (el.key) {
                case "1":
                  el[ele.month] = setDataIcon(ele.isWip);
                  el.wip = ele.month;
                  break;
                case "2":
                  el[ele.month] = setDataIcon(ele.isFinal);
                  el.final = ele.month;
                  break;
                case "3":
                  el[ele.month] = ele.globalLock;
                  el[ele.month + "mcId"] = ele.mcId;
                  el.make = item.name.toLowerCase();
                  break;
                default:
                  console.warn("error");
                  break;
              }
            });
          }
        });
      });
    };
    // 初始化数据
    const initData = async () => {
      const res = await getCurrentYear();
      yearValue.value = res.showYear.toString();
      disableTime = `${res.year}${String(res.month).padStart(2, "0")}`;
      getMakeData();
    };
    const openYear = ref(false);
    const handlerPanelChange = (value: string): void => {
      yearValue.value = moment(value).format("YYYY");
      openYear.value = false;
      getMakeData();
    };
    const handleCheckbox = async (record: any, month: string, item: any[], index: number) => {
      try {
        await getLock({ mcId: record[`${month}mcId`], flag: record[month] });
        if (index < 2) { // CBU,PBP
          if (item?.[2]) {
            item[2][month] = `font_family icon-shangchuan ${record[month] ? 'un-upload' : 'upload'}`
          }
        }
      } catch (e) {
        record[month] = !record[month];
      }
      for (const i in record) {
        if (record[i] == true) {
          tempBack.value = true;
          break;
        } else {
          tempBack.value = false;
        }
      }
    };
    initData();
    return {
      yearValue,
      openYear,
      handlerPanelChange,
      handleCheckbox,
      handleUpload,
      initData,
      ...toRefs(uploadDrawer),
      dataArray,
      monthSlots,
      tempBack
    };
  },
});
